import'../../vendor/assets/javascripts/customSelect';

$(function() {
  $("select.custom").customSelect();
  if ( !$('body').hasClass('editing') && $("#mmenu").length > 0 ) {
    $(".delay-show").show();
    const mmenu = new Mmenu( "#mmenu", {
      "extensions": [
        "position-back",
        "position-right"
      ],
      navbar: {
        add: true
      }
    });
    const api = mmenu.API;
    api.bind('open:start', function () {
      $("body").addClass("mm-opening");
    });
    api.bind('close:start', function () {
      $("body").removeClass("mm-opening");
    });
    $("#menu-icon").click(function(e) {
      e.preventDefault();
      if ( $("body").hasClass("mm-opening") ) {
        api.close();
      }
    });
  }
  init_overlay_closer();
  bind_ring_modal_events(true, 0);
});
if ( !$("body").hasClass("editing") ) {
  $(document).ready(function() {
    window.favorites_hover_start = null;
    $(".top-li.favorites").on("mouseenter", function() {
      if ( !$("header ul.favorites-slider").hasClass("slick-slider") ) {
        $("header ul.favorites-slider").slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true
        });
      }
      else {
        $("header ul.favorites-slider").slick('refresh');
      }
      window.favorites_hover_start = new Date().getTime();
      $("#favorites-dropdown").show();
    });
    $(".top-li.favorites").on("mouseleave", function() {
      var current = new Date().getTime();
      if ( window.favorites_hover_start ) { trigger_event_with_times('Viewed Favorites Preview', 'Header', window.favorites_hover_start, current); }
      $("#favorites-dropdown").hide();
    });
  });
}
$(".li-2").hover(function() {
  $(this).find(".level-3").toggle();
});

$('.faq-block .question').click(function(e) {
  e.preventDefault();
  $(this).toggleClass('shown');
  $(this).next('.answer').slideToggle();
  if ( $(this).hasClass("shown") ) {
    var page_title = $("head title").text().replace(" | Benchmark Rings","");
    if ( page_title.indexOf("Free Ring") >= 0 ) { page_title = "Free Ring Page"; }
    if ( page_title.indexOf("In-Store Preview") >= 0 ) { page_title = "In Store Preview Page"; }
    var question = $(this).find("h4").text().trim().replace(/"g/,"'").replace(/\?/g,"").replace(/”/g,"'").replace(/“/g,"'");
    trigger_event(("Frequently Asked: Opened " + question), page_title, '', 1);
  }
});

$(".faq-block .answer a").click(function(e) {
  var page_title = $("head title").text().replace(" | Benchmark Rings","");
  if ( page_title.indexOf("Free Ring") >= 0 ) { page_title = "Free Ring Page"; }
  if ( page_title.indexOf("In-Store Preview") >= 0 ) { page_title = "In Store Preview Page"; }
  var kind = $(this).attr("href").indexOf('mailto') >= 0 ? "Email Address" : "Link";
  var target = $(this).text().trim();
  trigger_event(("Frequently Asked: Clicked " + kind), page_title, target, 1);
});

$(".local, a[href^='#']").each(function() {
  if ($(this).attr("href").length > 3 && $(this).attr("href") != "#mmenu" ) {
    $(this).click(function(event) {
      event.preventDefault();
      $("html, body").animate({ scrollTop: $($(this).attr("href")).offset().top - 150 }, 1000);
    });
  }
});
$("#submit-header-search").click(function(e) {
  e.preventDefault();
  $(this).parent().trigger('submit');
});
$("#submit-footer-search").click(function(e) {
  e.preventDefault();
  $(this).parent().trigger('submit');
});
$(".dropdown.sml").on("mouseleave", function() {
  $(this).find(".right ul li").hide();
  $(this).find(".right ul li").first().css("display","list-item");
});

export function init_ring_slider(ring_id, is_modal) {

 // console.log("init ring slider for ring " + ring_id + ", is modal: " + is_modal);

  var isTouchDevice = 'ontouchstart' in document.documentElement;
  var sliders = ring_id == 0 ? $('.ring-photo-slider .unslider-holder') : (is_modal ? $('.ring-modal .ring-photo-slider#ring_' + ring_id + ' .unslider-holder'): $('.ring .ring-photo-slider#ring_' + ring_id + ' .unslider-holder'));

  $.each(sliders, function(k,s) {

    var slider = $(s);

    var context = 'Product Listing Page';
    if ( slider.closest(".ring-modal").length == 1 ) { context = 'Quick View'; }
    else if ( slider.closest(".ring-details").length == 1 ) { context = 'Product Detail Page'; }

    if ( slider && !slider.parent().hasClass("unslider") ) {

      slider.unslider({
        infinite: true,
        animation: 'horizontal',
        autoplay: false,
        arrows: false,
        keys: false
      });

      var viewing3dModel = false;

      slider.on('unslider.change', function(event, index, slide) {

        $(slide).closest(".unslider").addClass("unslider-loaded");

        var v = $(slide).data("video");

        var p = $(slide).data("path");

        viewing3dModel = $(slide).hasClass('three-dee');

        var shouldDisableQuickViewUI = false;

        // YouTube video ID
        if ( v && v != '' ) {

          shouldDisableQuickViewUI = true;
          var this_ring = $(slide).closest(".ring-photo-slider").attr("id");
          var on_this_video = false;

          var ring_name = $(slide).closest(".ring, .ring-details").data("style");
          if (ring_name) { trigger_event('Played Video', context, ring_name, 1); }

          // change other sliders back to image 1
          $.each( $('.ring-photo-slider .unslider-holder'), function(k2,sl) {

            var rps = $(sl).closest(".ring-photo-slider");

            var mm = (is_modal && rps.closest(".ring-modal").length == 1) || (!is_modal && rps.closest(".ring-modal").length == 0);

            if ( mm && rps.attr("id") == this_ring && $(sl).find(".if .vid").length > 0 ) {
              on_this_video = true;
            }

            var ht = $(sl).find(".if").length > 0 ? $(sl).find(".if").first().html() : "";
            if ( ht.length > 0 && !on_this_video ) {
              $(sl).find(".if").html("");
              var dt = $(sl).data("unslider");
              if (dt) { dt.animateHorizontal(0); dt.setIndex(0); }
            }
          });

          // play video for this slide
          $.each( $(slide).closest(".ring-photo-slider").find(".if"), function(k2,v2) {

            if ( !on_this_video) {
              var rand = Math.floor(Math.random() * 55555);
              var html = "<div class='vid' id='video_" + rand + "'></div>";
              $(v2).html(html);
              var player = new YT.Player(('video_' + rand), {
                width: '100%',
                height: '400',
                videoId: v,
                playerVars: { 'autoplay': 1, 'playsinline': 1, 'loop': 1, 'mute': 1, 'playlist': v, 'controls': 0, 'related': 0 },
                events: {
                  onReady: function(event) {
                    event.target.mute();
                    event.target.playVideo();
                  }
                }
              });
            }
          });

        }

        // Hosted 360 video
        else if ( p && p != '' ) {

          shouldDisableQuickViewUI = true;
          var this_ring = $(slide).closest(".ring-photo-slider").attr("id");
          var on_this_video = false;

          var ring_name = $(slide).closest(".ring, .ring-details").data("style");
          if (ring_name) { trigger_event('Played Video', context, ring_name, 1); }

          // change other sliders back to image 1
          $.each( $('.ring-photo-slider .unslider-holder'), function(k2,sl) {

            var rps = $(sl).closest(".ring-photo-slider");

            var mm = (is_modal && rps.closest(".ring-modal").length == 1) || (!is_modal && rps.closest(".ring-modal").length == 0);

            if ( mm && rps.attr("id") == this_ring && $(sl).find(".if .vid").length > 0 ) {
              on_this_video = true;
            }

            var ht = $(sl).find(".if").length > 0 ? $(sl).find(".if").first().html() : "";
            if ( ht.length > 0 && !on_this_video ) {
              $(sl).find(".if").html("");
              var dt = $(sl).data("unslider");
              if (dt) { dt.animateHorizontal(0); dt.setIndex(0); }
            }
          });

          // play video for this slide
          $.each( $(slide).closest(".ring-photo-slider").find(".if"), function(k2,v2) {

            if ( !on_this_video) {

              var rand = Math.floor(Math.random() * 55555);
              var div = $("<div />").addClass("vid frm").attr("id","video_" + rand);
              var url = "https://benchmark-rings.s3.amazonaws.com/360_new/" + p + "/iframe.html";

              var iframe = $("<iframe />").attr("src", url).attr("allowfullscreen","true").attr("scrolling","no").attr("frameborder","0");
              div.append(iframe);

              $(v2).empty().append(div);

            }
          });

        }

        // 3d model
        else if (viewing3dModel) {
          shouldDisableQuickViewUI = true;
          var this_ring = $(slide).closest(".ring-photo-slider").attr("id");

          var ring_name = $(slide).closest(".ring, .ring-details").data("style");
          if (ring_name) { trigger_event('Viewed 3D Model', context, ring_name, 1); }
          if (window.sync3dModelHeight) sync3dModelHeight();

        } else {
          $(slide).closest(".unslider-holder").find(".un-slide .if").html("");
          var ring_name = $(slide).closest(".ring, .ring-details").data("style");
          var image_index = $(slide).data("index");
          if ( image_index && image_index != '' && ring_name )
            trigger_event(('Viewed Image ' + image_index), context, ring_name, 1);
        }

        var $quickviewUI = $(slide).closest('.ring-img').find('.quick');
        if ($quickviewUI.length > 0) {
          $quickviewUI.toggleClass('passthrough', shouldDisableQuickViewUI);
        }
      });

      slider.find("li").show();
      var data = slider.data('unslider');
      var se = slider.closest(".ring-photo-slider").next(".quick").length == 0 ? slider.closest(".ring-photo-slider") : slider.closest(".ring-photo-slider").next(".quick");
      if ( se.closest(".featured-rings-block").length == 0 ) {
        se.swipe( {
          swipeLeft:function(event, direction, distance, duration, fingerCount, fingerData) {
            if ( isTouchDevice && !viewing3dModel ) { data.next(); }
          },
          swipeRight:function(event, direction, distance, duration, fingerCount, fingerData) {
            if ( isTouchDevice && !viewing3dModel ) { data.prev(); }
          }
        });
      }
    }
  });
}

$(".dropdown.sml .left a").hover(function() {
  var anc = $(this);
  var id = anc.parent().data("id");
  anc.closest(".flexbox").find(".right li").hide();
  anc.closest(".flexbox").find(".right li[data-id='" + id + "']").show();
});


export function open_fp(ring_id) {
  window.location = "/in-store-preview?ring_id=" + ring_id + "&retailer_id=" + window.retailer_id;
}

export function open_fr(ring_id) {
  window.location = "/free-ring?ring_id=" + ring_id + "&retailer_id=" + window.retailer_id;
}

$(window).on('load', function() {
  if ( $(".parallax-mirror").length > 0 )
    $(".banner.category, .banner.collection, .banner.social").css("background-color","transparent");
});

export function info_popup(el) {
  var is_fullscreen = $(window).width() < 800 && el.closest(".hold").find(".fullscreen-overlay").length > 0;
  var pop = is_fullscreen ? el.closest(".hold").find(".fullscreen-overlay") : el.closest(".hold").find(".info-popup");
  var hold = el.closest(".hold");

  var is_modal = pop.closest(".RingModal").length > 0;

  if ( $(window).width() < 600 ) {

    var is_bottom = true;

    if ( is_modal ) {
      var diff = $(".ring-details").scrollTop() - $(".ring-details").outerHeight();
      is_bottom = diff >= 100;
    }
    else {
      is_bottom = ($(window).scrollTop() + pop.outerHeight()) > hold.offset().top;
    }

    if ( is_bottom ) {
      pop.addClass("bt").removeClass("tp");
      if ( is_modal ) { pop.closest(".left-unit").css("padding-bottom","70px"); }
    }
    else {
      pop.addClass("tp").removeClass("bt");
      if ( is_modal ) { pop.closest(".left-unit").css("padding-bottom","0"); }
    }
  }

  if ( el.data("text") ) {
    var el2 = $("#" + el.data("text"));
    var html = el2 ? el2.html() : '';
    pop.html(html);
  }

  if ( pop.is(":visible") && !pop.hasClass("vb") ) {
    $(".info-popup").hide();
    pop.hide();
  }

  else {
    $(".info-popup").hide();
    pop.removeClass("vb");
    if (is_fullscreen) {
      pop.css("display","flex");
      disableScroll();
    }
    else
      pop.show();
  }
}

export function bind_ring_modal_events(is_details, ring_id, pref = null) {

  // console.log("binding ring modal events for ring " + ring_id);
  // console.log("is_details: " + is_details);

  var prefix = pref !== null ? pref : (ring_id == 0 ? ".content_body .ring-details " : (".ring-overlay-modal .ring-details#ring_" + ring_id + " "));
  var context = is_details ? 'Product Detail Page' : 'Quick View';

  $(".shopify-quantity-selector .btn").click(function(e) {
    e.preventDefault();
    if ( !$(this).hasClass("disabled") ) {
      var delta = $(this).hasClass("inc") ? 1 : -1;
      shopify_controller.updateQuantity($(this), delta);
      var context = $(".ring-overlay-modal").length > 0 ? "Quick View" : "Product Detail Page";
      trigger_event("Adjusted Quantity", context, '', 1);
    }
  });

  $(prefix + "a.show-edu").click(function(e) {
    e.preventDefault();
    if ( $(this).text() == "More details" )
      $(this).text("Hide details").next(".edu-drop").slideDown();
    else
      $(this).text("More details").next(".edu-drop").slideUp();
  });

  $(prefix + ".engraving_font_select").change(function() {
    var v = $(this).val();
    var op = v == 'Script' ? 'Block' : 'Script'
    $(this).closest(".eng-options").find(".eng-text").addClass(v).removeClass(op);
  });

  $(prefix + ".eng-text").on('input', function() {
    var start = this.selectionStart;
    var end = this.selectionEnd;
    var originalText = $(this).val();
    var correctedText = originalText.replace(/[^\x20-\x21\x26-\x29\x2b-\x3f\x41-\x5d\x61-\x7a]/g, '');
    var numCharsRemoved = originalText.length - correctedText.length;
    $(this).val(correctedText);
    this.setSelectionRange(start - numCharsRemoved, end - numCharsRemoved);

    var va = $(this).val();
    var rid = $(this).closest("form.shop-form").data("ringid");
    var l = va && va != '' ? va.length : 0;
    var d = (30 - l);
    $(this).prev(".top").find(".char-count-remain").text(d);
    shopify_controller.check_options_valid(rid, false);
  });

  $(prefix + "input.enable_engraving").change(function() {
    var rid = $(this).closest("form.shop-form").data("ringid");
    if ( $(this).is(":checked") ) {
      $(this).closest(".engraving").find(".eng-options").slideDown();
      const ringStyleNumber = $('.RingModal').length > 0 ? $('.RingModal').data('ringStyle') : $(this).closest(".ring-details").data("style");
      trigger_event('Clicked Engraving', context, ringStyleNumber, 1);
    }
    else
      $(this).closest(".engraving").find(".eng-options").slideUp();
    shopify_controller.check_options_valid(rid, false);
  });

  init_overlay_closer();

  var engraving_hover_start = false;
  var free_ring_hover_start = false;
  var green_hover_start = false;

  $(prefix + ".engraving .show-info-popup").on("mouseenter", function() {
    engraving_hover_start = new Date().getTime();
  });

  $(prefix + ".engraving .show-info-popup").on("mouseleave", function() {
    if ( engraving_hover_start ) {
      var current = new Date().getTime();
      trigger_event_with_times('Viewed Engraving Tips', context, engraving_hover_start, current);
    }
  });

  $(prefix + ".action-buttons .btn.pink, .ret-btns .btn.pink").on("mouseenter", function() {
    free_ring_hover_start = new Date().getTime();
  });

  $(prefix + ".action-buttons .btn.pink, .ret-btns .btn.pink").on("mouseleave", function() {
    if ( free_ring_hover_start ) {
      var current = new Date().getTime();
      trigger_event_with_times('Viewed Free Ring Pop Up', context, free_ring_hover_start, current);
    }
  });

  $(prefix + ".gallery .green").on("mouseenter", function() {
    green_hover_start = new Date().getTime();
  });

  $(prefix + ".gallery .green").on("mouseleave", function() {
    if ( green_hover_start ) {
      var current = new Date().getTime();
      trigger_event_with_times('Viewed Recycled Metal Pop Up', context, green_hover_start, current);
    }
  });

  $.widget( "custom.iconselectmenu", $.ui.selectmenu, {
    _renderItem: function( ul, item ) {
      var dis = item.element.attr('disabled') ? 'disabled' : '';
      var li = $( "<li>" ).addClass(dis);
      var span = $("<span />").addClass("mt");
      var img = (item.element.attr('data-img') ? ($("<img />").attr("src", item.element.attr('data-img')).attr("width",14).attr("height",14)) : '');
      span.append(img).append(item.label);
      li.append(span);
      return li.appendTo( ul );
    },
    _renderButtonItem: function( item ) {
      var span = $("<span />").addClass("mt");
      var dis = item.element.attr('disabled') ? 'disabled' : '';
      var img = (item.element.attr('data-img') ? ($("<img />").attr("src", item.element.attr('data-img')).attr("width",14).attr("height",14)) : '');
      var lbl = dis ? "Avail. Sizes" : item.label;
      span.append(img).append(lbl);
      return span;
    }
  });

  $("select.slt").iconselectmenu({
    change: function(e, ui) {
      shop_option_change(ui.item, is_details);
    },
    open: function(e) {

      // scroll to the seleted metal
      if ( e && e.target && e.target.id && e.target.id.indexOf("metal_select") >= 0 ) {
        var el = $(prefix + "ul.ui-menu[id*='metal_select']").find("span.ui-state-active").first().parent()[0];
        var sp = el ? el.offsetTop : 0;
        $(prefix + "ul.ui-menu[id*='metal_select']").parent().animate({ scrollTop: sp}, 0);
      }

      // after clicking a disabled size (for made to order rings), show the "how to select size" popup
      $(prefix + ".ui-menu-item.disabled").click(function() {
        var rand = (Math.floor(Math.random() * 999999)).toString();
        $(prefix + ".mtos.hold").find(".info-popup").addClass("vb").show().attr("data-click",rand);
        if ( $(window).width() < 800 ) {
          setTimeout( function() {
            $("body").click(function(e) {
              var mod = $(prefix + ".mtos.hold").find(".info-popup.vb[data-click='" + rand + "']");
              if ( mod.length > 0 ) {
                var min_x = mod.offset().left;
                var max_x = min_x + mod.outerWidth();
                var min_y = mod.offset().top;
                var max_y = min_y + mod.outerHeight();
                if ( (e.screenX == 0 && e.screenY == 0) ||  (e.pageX > min_x && e.pageX < max_x && e.pageY > min_y && e.pageY < max_y) ) { }
                else {
                  $(prefix + ".mtos.hold").find(".info-popup").removeClass("vb").hide().removeAttr("data-click");
                }
              }
            });
          }, 500);
        }
      });

    }
  }).iconselectmenu("menuWidget").addClass("ui-menu-icons customicons");

  $.each( $(".show-info-popup"), function(k,v) {
    var el = $(v);
    if ( !el.hasClass('bd') ) {
      el.addClass('bd');
      if ( el.hasClass("fs") && $(window).width() < 800 ) {
        el.click(function(e) {
          e.preventDefault();
          info_popup(el);
        });
      }
      else {
        el.hover(function() {
          info_popup(el);
        });
        if ( el.attr("href") != "/free-rings" ) {
          el.click(function(e) {
            e.preventDefault();
          });
        }
      }
    }
  });

  var cel = $(prefix + ".enable_engraving");
  var rel = $(prefix + ".engraving_font_select");
  customCheckbox(cel);
  customRadio(rel);

  $(prefix + ".show-info-popup").click(function(e) {
    if ( $(this).hasClass("sr-fp") )
      if ( $(window).width() <= 800 )
        e.preventDefault();
    else if ( !$(this).hasClass("fp") )
      e.preventDefault();
  });

  var also_1_el = $(prefix + ".also-1");
  var also_2_el = $(prefix + ".also-2");

  if ( also_1_el.length > 0 ) {
    var w = also_1_el.data("width");
    if ( !w || w == '' ) { w = 3; }
    also_1_el.find("ul").slick({
      infinite: true,
      slidesToShow: w,
      slidesToScroll: w,
      arrows: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    });
    also_1_el.find("ul").on("swipe", function(event, slick, direction) {
      var dir = direction == "left" ? "Right" : "Left";
      trigger_event("Scrolled Also Available Style List", context, dir, 1);
    });
    also_1_el.find(".slick-arrow.slick-prev").click(function(e) {
      trigger_event("Scrolled Also Available Style List", context, "Left", 1);
    });
    also_1_el.find(".slick-arrow.slick-next").click(function(e) {
      trigger_event("Scrolled Also Available Style List", context, "Right", 1);
    });
  }

  if ( also_2_el.length > 0 ) {
    var w = also_2_el.data("width");
    if ( !w || w == '' ) { w = 3; }
    also_2_el.find("ul").slick({
      infinite: true,
      slidesToShow: w,
      slidesToScroll: w,
      arrows: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    });
    also_2_el.find("ul").on("swipe", function(event, slick, direction) {
      var dir = direction == "left" ? "Right" : "Left";
      trigger_event("Scrolled Also Available Style List", context, dir, 1);
    });
    also_2_el.find(".slick-arrow.slick-prev").click(function(e) {
      trigger_event("Scrolled Also Available Style List", context, "Left", 1);
    });
    also_2_el.find(".slick-arrow.slick-next").click(function(e) {
      trigger_event("Scrolled Also Available Style List", context, "Right", 1);
    });
  }

  if ( !is_details ) {
    init_ring_slider(ring_id, true);
    $(".ring-modal .icon-close").click(function(e) {
      e.preventDefault();
      var over = $(this).closest(".fullscreen-overlay");
      over.fadeOut();
      enableScroll();
    });
    //init_overlay_closer();
    shopify_controller.after_init();
  }
  else {
    init_ring_slider(ring_id, false);
  }
}

export function customCheckbox(elements) {
  $(elements).each(function(){
    $(this).wrap( "<span class='custom-in cb'></span>" );
    if ( $(this).is(':checked') )
      $(this).parent().addClass("selected");
  });
  $(elements).click(function(){
    $(this).parent().toggleClass("selected");
  });
}

export function customRadio(elements) {
  $(elements).each(function(){
    $(this).wrap( "<span class='custom-in rd'></span>" );
    if ( $(this).is(':checked') )
      $(this).parent().addClass("selected");
  });
  $(elements).click(function(){
    $(this).closest("form").find(".custom-in.rd").removeClass("selected");
    $(this).parent().addClass("selected");
  });
}

export function shop_option_change(item, is_details) {
  var el = $(item.element).closest("select");
  if ( el.hasClass("ring-size-select") ) {
    var rid = el.closest("form.shop-form").data("ringid");
    shopify_controller.check_options_valid(rid, false);
  }
  else if ( el.hasClass("metal") ) {
    var form = el.closest(".shop-form");
    var curr = form.data("ringid");
    var metal = form.find("select[name='ring_metal_select']").val();
    var size = form.find("select[name='ring_finger_size']").val();
    var sr = $("span.sri[data-metal='" + metal + "']").first();
    var use_engraving =   $(form).find(".enable_engraving").is(":checked");
    var engraving_font =  $(form).find(".engraving_font_select:checked").val();
    var engraving_text =  $(form).find(".eng-text").val();
    var quantity = $('.RingModal').length > 0 ? +($('.RingModal .AddToBagButton').data('quantity') || 0) : parseInt( $(form).find(".shopify-quantity-input").val());
    var line_item_id = $(form).find(".shopify-update-cart").data("line-item");
    if ( sr.length == 1 && sr.data("id") != curr ) {
      var sz = size && size != '' ? ("&size=" + size) : "";
      if ( is_details ) {
        trigger_event("Changed Metal", 'Product Detail Page', '', 1);
        window.location = sr.data("url") + "?width=select" + sz;
      }
      else {
        trigger_event("Changed Metal", 'Quick View', '', 1);
        // show_ring_modal(sr.data("id"), true, size, use_engraving, engraving_text, engraving_font, quantity, line_item_id);

        const lineItemData = {
          size,
          engraving: use_engraving,
          text: engraving_text,
          font: engraving_font,
          quantity: quantity,
          edit_mode: !!line_item_id,
          line_item_id: line_item_id
        };

        showRingModal(sr.data('id'), lineItemData);
      }
    }
  }
  else if ( el.hasClass("width") ) {
    var rid = el.closest("form.shop-form").data("ringid");
    var form = el.closest(".shop-form");
    var curr = form.data("ringid");
    var metal = form.find("select[name='ring_metal_select']").val();
    var width = form.find("select[name='ring_width_select']").val();
    var size = form.find("select[name='ring_finger_size']").val();
    var sr = $("span.sri[data-metal='" + metal + "'][data-size='" + width + "']").first();
    var use_engraving =   $(form).find(".enable_engraving").is(":checked");
    var engraving_font =  $(form).find(".engraving_font_select:checked").val();
    var engraving_text =  $(form).find(".eng-text").val();
    var quantity = $('.RingModal').length > 0 ? +($('.RingModal .AddToBagButton').data('quantity') || 0) : parseInt( $(form).find(".shopify-quantity-input").val());
    var line_item_id = $(form).find(".shopify-update-cart").data("line-item");
    shopify_controller.check_options_valid(rid, false);
    if ( sr.length == 1 && sr.data("id") != curr ) {
      var sz = size && size != '' ? ("?size=" + size) : "";
      if ( is_details ) {
        trigger_event("Changed Ring Width", 'Product Detail Page', '', 1);
        window.location = sr.data("url") + sz;
      }
      else {
        trigger_event("Changed Ring Width", 'Quick View', '', 1);
        // show_ring_modal(sr.data("id"), false, size, use_engraving, engraving_text, engraving_font, quantity, line_item_id);
        const lineItemData = {
          size,
          engraving: use_engraving,
          text: engraving_text,
          font: engraving_font,
          quantity: quantity,
          edit_mode: !!line_item_id,
          line_item_id: line_item_id
        };

        showRingModal(sr.data('id'), lineItemData);
      }
    }
  }
}

export function showRingModal(ringID, lineItemOptions = undefined) {
  const $existingModal = $('.RingModalWrapper');
  $existingModal.remove();

  const $newModalBackdropWrapper = $('<div class="RingModalWrapper"></div>').html(`<div class="RingModalOverlay">`)
  injectLoadingIndicator($newModalBackdropWrapper[0], { color: '#fff' })
  $newModalBackdropWrapper.prependTo(document.body);

  $.ajax({
    url: `/api/rings/${ringID}/modal`,
    type: 'GET',
    data: lineItemOptions,
    success: function(response) {
      const $existingModal = $('.RingModalWrapper');
      $existingModal.remove();

      const $modalWrappper = $('<div></div>');
      $modalWrappper.html(response);
      const $modal = $($modalWrappper.children()[0]);
      $modal.prependTo(document.body);

      $modal.find('.RingModalOverlay,[data-action="close-modal"]').on('click', dismissRingModal);

      const ringID = $modal.find('.RingModal').data('ringId')

      bind_ring_modal_events(false, ringID, '')

      if (window.StampedFn) {
        window.StampedFn.loadBadges();
      }

      if (window.affirm) {
        affirm.ui.ready(function() {
          affirm.ui.refresh();
        });
      }

      sync3dModelHeight();

      const context = window.location.pathname === '/'  ? 'Homepage' : 'Product Listing Page';
      const ringStyleNumber = $('.RingModal').data('ringStyle');
      trigger_event('Viewed Quick View', context, ringStyleNumber, 1);
    }
  });
}

export function dismissRingModal() {
  $('.RingModalWrapper').fadeOut(undefined, undefined, () => {
    $('.RingModalWrapper').remove();
  });

  window.customizationProductID = window.oldCustomizationProductID;
  window.customizationFee = window.oldCustomizationFee;
  window.customizationDescription = window.oldCustomizationDescription;
  window.customizationId = window.oldCustomizationId;
}

export function show_ring_modal(ring_id, width_empty, size, use_engraving, engraving_text, engraving_font, quantity, line_item_id, customization_id = '') {
  var el = $(".ring-overlay-modal").first();
  var h = el.find(".ring-details").outerHeight();
  var context = 'Product Listing Page';
  if ( $(".rotating-banners").length == 1 ) { context = 'Homepage'; }
  if ( window.logging ) { console.log("showing ring modal for ring " + ring_id); }
  el.find(".loading-overlay").css("height",h + 20 + "px");
  if ( el.length == 0 ) {
    var div = $("<div />").addClass("ring-overlay-modal fullscreen-overlay");
    $("body").append(div);
    el = div;
  }
  else {
    el.find(".ring-details").css("opacity",0);
  }
  el.addClass('note loading').fadeIn();
  disableScroll();
  var sz = size && size != '' ? ("&size=" + size) : "";
  $.ajax({
    url: '/api/rings/' + ring_id + '/modal',
    type: 'get',
    data: {
      width_empty: width_empty,
      size: size,
      engraving: use_engraving,
      text: engraving_text,
      font: engraving_font,
      quantity: quantity,
      edit_mode: ( line_item_id && line_item_id != '' ? true : false ),
      line_item_id: line_item_id,
      customization_id: customization_id
    },
    success: function(resp) {
      if ( resp.success && resp.html ) {
        el.html(resp.html);
        el.removeClass('loading');
        el.find(".loading-overlay").css("height","");
        bind_ring_modal_events(false, ring_id);

        if (window.StampedFn) {
          window.StampedFn.loadBadges();
        }

        if (window.affirm) {
          affirm.ui.ready(function() {
            affirm.ui.refresh();
          });
        }

        sync3dModelHeight();

        var ring_name = el.find(".ring-details").data("style");
        if ( !line_item_id ) {
          trigger_event('Viewed Quick View', context, ring_name, 1);
        }
      }
    }
  });
}

export function save_ring(rid, img, uri) {
  if ( window.logging ) { console.log("saving ring: " + rid.toString()); }
  var rid = rid.toString();
  var current = Cookies.get("saved_rings") ? Cookies.get("saved_rings").split('B') : [];
  if ( current.indexOf(rid) < 0 ) { // is new ring
    current.push(rid);
    Cookies.set("saved_rings",current.join('B'), { expires: 30, path: '/' });
    var count = current.length;

    $('.mobile-favorites .NotEmptyIndicator,nav .favorites .NotEmptyIndicator').toggleClass('hidden', count === 0);

    $(".saved-count").each(function(k,v) {
      var c = $(v).data("count") + 1;
      $(v).data('count',c).text(c);
    });
    $.ajax({
      url: '/api/rings/basic/' + rid,
      type: 'get',
      success: function(resp) {
        var html = `<li class="fave sr-${rid}"><a href="/favorites" class="img"><img src="${img}" /></a><p style="text-wrap: pretty;">${resp.friendly_name} <b style="font-size: 0.5rem">®</b></p></li>`;

        if ( $("header ul.favorites-slider").hasClass("slick-slider") ) {
          $("header ul.favorites-slider").slick('slickAdd', html);
          $("header ul.favorites-slider").slick('refresh');
        }
        else {
          $("header ul.favorites-slider").append(html);
        }
        $("#favorites-dropdown").attr("data-count", count);
      }
    });
    $('.save-ring.icon2-heart1').each(function(k,v) {
      if ( $(v).data("id") == rid ) {
        var oc1 = $(v).attr("onclick");
        var oc2 = $(v).attr("onclick2");
        $(v).removeClass('icon2-heart1').addClass('icon2-heart2').attr("onclick",oc2).attr("onclick2",oc1);
      }
    });
  }
}

export function unsave_ring(rid) {
  if ( window.logging ) { console.log("unsaving ring: " + rid.toString()); }
  var rid = rid.toString();
  var current = Cookies.get("saved_rings") ? Cookies.get("saved_rings").split('B') : [];
  var ind = current.indexOf(rid);
  if ( ind >= 0 )
    current.splice(ind,1);
    Cookies.set("saved_rings",current.join('B'), { expires: 30, path: '/' });
  var count = current.length;
  $('.mobile-favorites .NotEmptyIndicator,nav .favorites .NotEmptyIndicator').toggleClass('hidden', count === 0);
  $(".saved-count").each(function(k,v) {
    var c = $(v).data('count') - 1;
    $(v).data('count',c).text(c);
  });
  if ( $("header ul.favorites-slider").hasClass("slick-slider") ) {
    $.each( $(".fave.sr-" + rid), function(k,v) {
      var ind = $(v).data("slick-index");
      $("header ul.favorites-slider").slick('slickRemove', ind);
    });
    $("header ul.favorites-slider").slick('refresh');
  }
  else {
    $(".sr-" + rid).remove();
  }
  $(".saved-ring.sr-" + rid).remove();
  $('.save-ring.icon2-heart2').each(function(k,v) {
    if ( $(v).data("id") == rid ) {
      var oc1 = $(v).attr("onclick");
      var oc2 = $(v).attr("onclick2");
      $(v).removeClass('icon2-heart2').addClass('icon2-heart1').attr("onclick",oc2).attr("onclick2",oc1);
    }
  });
  $("#favorites-dropdown").attr("data-count", count);
}

$(".info-popup .icon-close").click(function(e) {
  e.preventDefault();
  var over = $(this).closest(".fullscreen-overlay");
  over.fadeOut();
  enableScroll();
});

export function init_overlay_closer(prefix) {
  var pre = prefix ? (prefix + " ") : "body";
  $.each( $(pre + " .fullscreen-overlay"), function() {
    var fso = $(this);
    if ( !fso.hasClass("bd") ) {
      fso.addClass("bd").click(function(e) {
        var mod = $(this).find(".modal, .info-popup");
        if ( mod.length > 0 ) {
          var min_x = mod.offset().left;
          var max_x = min_x + mod.outerWidth();
          var min_y = mod.offset().top;
          var max_y = min_y + mod.outerHeight();
          if ( (e.screenX == 0 && e.screenY == 0) ||  (e.pageX > min_x && e.pageX < max_x && e.pageY > min_y && e.pageY < max_y) ) { }
          else {
            var over = $(this);
            over.fadeOut();
            enableScroll();
          }
        }
      });
      fso.find(".icon-close").click(function(e) {
        e.preventDefault();
        fso.fadeOut();
        enableScroll();
      });
    }
  });
}

export function show_material_modal(img, collection, application, title, zip, flipbook, file, col_id, onclick) {
  $("#material-modal").find("#title").text(title);
  $("#material-modal").find("#collection").text(collection);
  $("#material-modal").find("#application").text(application);
  $("#material-modal").find("#image").attr('src',img);
  if ( file && file.indexOf('mp4') > 0 ) {
    $("#material-modal #video").html("<video width='889' height='500' autoplay controls><source src='" + file + "' type='video/mp4'></video>").show();
    $("#material-modal").find("#image").hide();
    $("#material-modal").find("#dl").attr('href',file).attr('onclick',onclick);
  }
  else {
    $("#material-modal #video").hide();
    $("#material-modal").find("#image").show();
    $("#material-modal").find("#dl").attr('href',zip).attr('onclick',onclick);
    if ( flipbook ) {
      $("#material-modal").addClass("flip");
      $("#material-modal").find("#fb").html(flipbook);
      $("#material-modal").find("#image").hide();
    }
    else {
      $("#material-modal").removeClass("flip");
      $("#material-modal").find("#fb").html("");
      $("#material-modal").find("#image").show();
    }
  }
  $("#material-modal").css("top",$("#col-" + col_id).offset().top - 100).fadeIn();
  disableScroll();
  trigger_event('Marketing Material Viewed', application, title, 1);
}

$(".material-view").click(function(e) {
  e.preventDefault();
  show_material_modal( $(this).data('img'), $(this).data('collection'), $(this).data('application'), $(this).data('title'), $(this).closest(".material").find(".zip").attr('href'), $(this).parent().siblings(".flipbook").html(), $(this).data('file'), $(this).data('col'), $(this).closest(".material").find(".zip").attr('onclick')  );
});

$(".submit-appointment-form").click(function(e) {
  e.preventDefault();
  var btn = $(this);
  if ( !btn.hasClass("disabled") ) {
    btn.addClass("disabled");
    var form = $(this).closest("form");
    var mess = form.next("#message");
    mess.html("<p class='note loading'>Submitting...</p>");
    if (window.logging) { console.log("ajax call: submit_appointment()"); }
    $.ajax({
      url: '/appointment/submit',
      data: form.serialize(),
      type: 'post',
      success: function(resp) {
        if ( resp && resp.error ) {
          btn.removeClass("disabled");
          mess.html("<p class='note error'>" + resp.error + "</p>");
        }
        else if ( resp && resp.redirect ) {
          var form_title = form.closest(".appointment-page").find("h1.lined").text().trim().replace(" Request Form","").replace("Book an ","");
          trigger_event('Submitted Form', 'Form Page', form_title, 1);
          window.location = resp.redirect;
        }
      }
    });
  }
});

export function disableScroll() {
  $("body").addClass("noscroll");
}

export function enableScroll() {
  $("body").removeClass("noscroll");
}

$(".review table .text a.add").click(function(e) {
  e.preventDefault();
  $(this).next("textarea").slideToggle();
});

$(".submit-modify-form").click(function(e) {
  e.preventDefault();
  var form = $(this).closest("form");
  var mess = form.next("#message");
  mess.html("<p class='note loading'>Submitting...</p>");
  if (window.logging) { console.log("ajax call: modify_appointment()"); }
  $.ajax({
    url: form.attr('action'),
    data: form.serialize(),
    type: 'put',
    success: function(resp) {
      if ( resp && resp.error ) {
        mess.html("<p class='note error'>" + resp.error + "</p>");
      }
      else if ( resp && resp.redirect ) {
        window.location = resp.redirect;
      }
    }
  });
});

$(".open-chat").click(function(e) {
  e.preventDefault();
  purechatApi.set('chatbox.expanded', true);
});

setInterval(function() {
  var gridReviewBadgesToFix = $('.rings-grid-wrapper .stamped-fa-star[style*="color:"], .rings-grid-wrapper .stamped-fa-star-o[style*="color:"], .rings-grid-wrapper .stamped-fa-star-half[style*="color:"], .rings-grid-wrapper .stamped-fa-star-half-o[style*="color:"]');
  if (gridReviewBadgesToFix.length > 0) {
    gridReviewBadgesToFix.removeAttr('style');
  }
}, 100);

export function sync3dModelHeight() {
  const sliderWrappers = document.querySelectorAll('.unslider-holder');

  sliderWrappers.forEach(function(sliderWrapper) {
    const modelViewers = sliderWrapper.querySelectorAll('model-viewer');
    modelViewers.forEach(function(modelViewer) {
      modelViewer.style.height = sliderWrapper.offsetHeight + 'px';
    });
  });
}

sync3dModelHeight();
window.onresize = sync3dModelHeight;

async function onSwatchClick(event) {
  const $swatch = event.target.classList.contains('swatch') ? $(event.target) : $(event.target).closest('.swatch');
  const ringID = $swatch[0].dataset.ringId;
  const $ring = $(event.target).closest('.ring');
  if (ringID) {
    injectLoadingIndicator($ring[0]);
    const ringResponse = await fetch(`/api/rings/single/${ringID}`);
    const ring = await ringResponse.json();
    ring.initialMetalID = $ring.data('initialMetalId');
    const ringHTML = buildRingHTML(ring);
    const $newRing = $(ringHTML);
    $ring.children(':not(.heart-container)').remove();
    $ring.append($newRing.children());
    window.StampedFn.loadBadges();
    removeLoadingIndicator($ring[0]);
    $ring.find('.main-images').on('scroll', onMobileProductImageScroll);
  } else {
    removeLoadingIndicator($ring[0]);
    throw new Error('Ring id was null');
  }
}

export function injectLoadingIndicator(parentElement, options={}) {
  const $loadingIndicator = $(buildLoadingIndicatorHTML(options));
  $(parentElement).append($loadingIndicator)
}

export function removeLoadingIndicator(parentElement) {
  $(parentElement).find('loading-indicator').remove();
}

export function buildLoadingIndicatorHTML(options) {
  var html = `
    <div class="loading-indicator" style="
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00000012;
      backdrop-filter: blur(2px);
      z-index: 4;">
      <p style="
        height: 50px;
        width: 50px;
        animation: loader 700ms infinite linear;
        border-radius: 50%;
        border: 3px solid rgb(0 0 0 / 30%);
        border-top-color: ${options.color || '#000'};">
      </p>
      <style>
        @keyframes loader {
          0% {
            transform: rotate(0deg)
          }
          25% {
            transform: rotate(90deg)
          }

          50% {
            transform: rotate(180deg)
          }

          75% {
            transform: rotate(270deg)
          }

          100% {
            transform: rotate(360deg)
          }
        }
      </style>
    </div>
  `;
  return html;
}

export function buildRingHTML(ring) {
  var price = ring.price ? format_price(ring.price, 2, ".", ",").replace(".00","") : "";
  var sale_price = ring.sale_price ? format_price(ring.sale_price, 2, ".", ",").replace(".00","") : "";
  var selling_price = ring.sale_price && ring.on_sale ? ring.sale_price : ring.price;

  const ringImageKeys = [1, 5, 4, 3, 2];
  const ringImageURLs = ringImageKeys.map(key => ring[`photo${key}_url_md`]).filter(url => !url.endsWith('ring.png'));
  const shownImageURLs = ringImageURLs.slice(0,2);

  const mobilePageIndicator = `
  <div class="mobile-page-indicator-container">
    <div class="mobile-page-inner">
      ${ shownImageURLs.map((_, i) => `<span class="page-indicator ${ i === 0 ? 'selected page-in' : 'page-out' }"></span>`).join('') }
    </div>
  </div>
  `;

  const imageHTML = `
  <div class="main-images">
    ${shownImageURLs.map((url, i) => `<a href="${ring.url}"><img src="${ url }" alt="${ring.full_style_number}_${i + 1}"></a>`).join('')}
  </div>
  `;

  const madeToOrderImgHTML = `
    <img class="mto" src="//assets.benchmarkrings.com/assets/made_to_order.svg" height="50" alt="Made to Order" />
  `;

  const saleIndicatorHTML = `
    <span class="on-sale">Sale</span>
  `;

  const buyNowPricingHTML = `
    <p class="is" itemprop="offers" itemtype="http://schema.org/Offer">
      <span class="hidden" itemprop="priceCurrency" content="USD">USD</span>
      <span class="hidden" itemprop="itemCondition" content="NewCondition">New Condition</span>
      <span class="hidden" itemprop="availability" content="InStock">In Stock</span>
      <span class="hidden"itemprop="url">${ ring.abs_url }</span>
      <span class="hidden" itemprop="price">${ selling_price }</span>
      ${ ring.on_sale ?
        `
          <span class="og">${ price }</span>
          <span class="sp">${ sale_price }</span>
        ` : `<span class="cp">${ price }</span>`
      }
  </p>
  `;

  const inStorePricingHTML = `
      <div class="iso hold">
        <p class="is">
          In-Store Only
          <a href="#" data-text="is-edu" onclick="return false;" class="icon2-info show-info-popup"></a>
        </p>
        <div class="info-popup white" style="display: none;">
          <p>This is an exclusive style and is only available to purchase through one of our retailers. Sign up for an in-store preview at your nearest Platinum Retailer!</p>
        </div>
      </div>
  `;

  const stampedReviewHTML = `
    <a href="${ ring.url }#stamped-main-widget">
      <span class="stamped-product-reviews-badge stamped-main-badge" data-id="${ ring.shopify_product_id }" data-product-title="${ ring.short_description }">
      </span>
    </a>
  `;

  var html = `
  <div data-style="${ ring.full_style_number }" data-ref="item" itemtype="http://schema.org/Product" itemid="${ ring.url }" data-initial-metal-id="${ ring.initialMetalID || ring.metal_id }" data-parent-ring-id="${ ring.parent_ring_id }" class="ring">
    <span class="hidden" itemprop="brand" itemtype="http://schema.org/Brand" itemscope="">
      <span itemprop="name">Benchmark Rings</span>
    </span>
    <span class="hidden sku-prop" itemprop="sku"content="${ ring.full_style_number }">${ ring.full_style_number }</span>
    <span class="hidden" itemprop="productID" content="sku:${ ring.full_style_number }">${ ring.full_style_number }</span>
    <div class="ring-box">
      ${ ring.made_to_order ? madeToOrderImgHTML : '' }
      ${ ring.on_sale ? saleIndicatorHTML : '' }
      <div class="ring-img">
        ${mobilePageIndicator}
        ${imageHTML}
        <div class="quick bd" data-href="${ ring.url }">
          <a class="desktop-ring-clickable" href="${ ring.url }"></a>
          <a href="#" onclick="showRingModal(${ ring.ring_id });return false;" class="btn trans">Quick View</a>
        </div>
      </div>
    </div>
    <div class="ring-info">
      <p class="sd">
        <a href="${ ring.url }">
          <span itemprop="name">${ ring.friendly_name }</span>
        </a>
      </p>
      <p class="mm" itemprop="description">
        <span>${ ring.size_name }</span> - <span>${ ring.metal_name }</span>
      </p>
      ${ ring.buy_now && ring.shopify_product_id ? buyNowPricingHTML : inStorePricingHTML }
      ${ ring.short_description && ring.shopify_product_id ? stampedReviewHTML : ''}
    </div>
  </div>
  `;

  return html;
}

export function format_price(number) {
  var decPlaces = 2, decSep = ".", thouSep = ",";
  var sign = number < 0 ? "-$" : "$";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + thouSep : "") + i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) + (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

export function hookScrollFixToCollectionImages() {
  $('.main-images img:nth-child(2)').each((_, img) => {
		if (!img.complete) {
			$(img).on('load', (e) => {
				e.target.parentElement.scroll(0,0);
			})
		} else {
			img.parentElement.scroll(0,0);
		}
	});
}

export function onMobileProductImageScroll(event) {
  const $target = $(event.target);
  const $imageContainer = $target.closest('.ring-img')
  const $pageIndicators = $imageContainer.find('.page-indicator');

  const isShowingPage2 = $target.scrollLeft() >= $target.width() * (2/3);

  $pageIndicators.first().toggleClass('selected page-in', !isShowingPage2)
                         .toggleClass('page-out', isShowingPage2);
  $pageIndicators.eq(1).toggleClass('selected page-in', isShowingPage2)
                       .toggleClass('page-out', !isShowingPage2);
}

export function toggleHeaderSearch(force = undefined) {
	const $searchContainer = $('.SearchSlideDown');
	const $input = $searchContainer.find('input');
	$input.val('');
  const flippedForce = typeof(force) === 'undefined' ? undefined : !force;
	$searchContainer.toggleClass('hidden', flippedForce);

  if (!$searchContainer.hasClass('hidden')) {
    $input.focus();
  }
}
