import "core-js/stable";
import "regenerator-runtime/runtime";

import 'jquery';
import {} from 'jquery-ujs';

import '../../vendor/assets/javascripts/mmenu';
import '../../vendor/assets/javascripts/parallax';
import '../../vendor/assets/javascripts/hoverIntent';
import '../../vendor/assets/javascripts/slick';
import '../../vendor/assets/javascripts/input_mask';
import '../../vendor/assets/javascripts/unslider';
import '../../vendor/assets/javascripts/touchSwipe';

global.Cookies = require('js-cookie');

import * as formatters from './formatters';
import * as mainFunctions from './main_functions';
import * as events from './events';
import * as retailers from './retailers';
import * as shopifyController from './shopify_controller';
import * as cartProvider from './cart_provider';
import * as formSubmit from './form_submit';
import * as collectionSortFilterController from './collection_sort_filter_controller';
import * as googleMapController from './google_map_controller';
import * as retailerMapController from './retailer_map_controller';

const globalModules = [
  formatters,
  mainFunctions,
  events,
  retailers,
  shopifyController,
  cartProvider,
  formSubmit,
  collectionSortFilterController,
  googleMapController,
  retailerMapController
];

globalModules.forEach(mod => Object.keys(mod).forEach(key => {
  global[key] = mod[key]
  window[key] = mod[key]
}));
